
import {
    FilterFormDefinitive,
    FormActions,
    ResponseDataStringArray as rdsa
} from "@/interfaces";
import * as types from "@/store/types";
import {
    Component,
    ModelSync,
    Prop,
    PropSync,
    Ref,
    Vue
} from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInputPre.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE,
            setEmpresa: types.MUTATE_EMPRESA
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER,
            Nempresa: types.EMPRESA
        })
    }
})
export default class FilterForm extends Vue {
    @ModelSync("filterForm", "change", { type: Object })
    readonly formValues!: FilterFormDefinitive;

    @Ref("form") readonly formActions!: FormActions;

    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    @Prop({ type: Boolean, default: true, required: false })
    readonly displayDates!: boolean;

    setFilterLoading!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    setEmpresa!: (state: number) => void;

    path = this.$route.path;
    userIdentifier?: number;
    Nempresa?: number;
    companies: string[] = [];
    boxes: string[] = [];
    clients: string[] = [];
    installations: string[] = [];
    analyzers: string[] = [];

    elements: string[] = [];
    listElements: any[] = [];
    paramElments: any[] = [];
    childElements: string[] = [];
    selected: string[] = [];
    selectedChild: string[] = [];

    datadis: string[] = [];
    listDatadis: string[] = [];
    paramsDatadis: any[] = [];
    selectedDatadis: string[] = [];

    services: object = {};
    listServices: any[] = [];
    paramsServices: any[] = [];
    selectedServices: object = {};

    inversors: string[] = [];
    listInversors: any[] = [];
    paramInversors: any[] = [];
    Iselected: string[] = [];

    periodType: string[] = [];
    modos = ["Contrato", "Precio de Mercado"];
    advancedOptions: boolean | null = false;
    isFromMap = false;
    start1 = "";
    start2 = "";
    // if !comparative -> dates, else start1 and start2
    first = false;
    isClient = true;
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 3 || "Maximo 3 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    formRules = {
        window: [
            val =>
                /[0-9][d]{1}$|[0-9][h]{1}$|[0-9][m]{1}$|[0-9][s]{1}$/.test(
                    val
                ) || val == null
        ]
    };

    loading = false;

    installFromMap: any | undefined | null = {};
    storageData: any | undefined | null = {};
    storageInstallations: string | undefined | null = "";
    // instantCompany: any = {};
    instantClient: any = {};
    instantInstall: any = {};
    habemusData = false;

    created() {
        this.windowUpdate();
        this.formValues.elements = [];
        this.formValues.window = "1dia";
        this.formValues.installation = "";
        this.formValues.comparative = false;
        this.formValues.days = "30";
        this.formValues.noHijos = false;
        this.formValues.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
                .toISOString()
                .split("T")[0],
            new Date().toISOString().split("T")[0]
        ];
        this.fetchUser();
        this.$root.$on("ResumenOnMap", () => {
            this.installationFromMap();
        });
    }

    /**
     * Methods
     */

    setFilter() {
        this.$emit("on-filter-updated");
    }

    datesUpdates() {
        if (this.start1 && this.start2) {
            this.formValues.dates = [this.start1, this.start2];
            this.windowUpdate();
        }
    }

    async fetchUser() {
        if (Vue.prototype.$user.get().role == "client") {
            this.setFilterLoading(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.setEmpresa(
                        Number(userClientResponse.data[0].split(" - ")[0])
                    );
                    this.formValues.company = userClientResponse.data[0];
                    this.formValues.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setFilterLoading(false);
            }
        } else if (Vue.prototype.$user.get().role == "admin") {
            this.isClient = false;
            this.storageInstallations = localStorage.getItem(
                "storageInstallations"
            );
            if (
                this.storageInstallations == null ||
                this.storageInstallations == undefined ||
                this.storageInstallations == ""
            ) {
                try {
                    // throw Error;
                    const storageInstallationsResponse = await this.$api.SQLstorageInstallations<
                        any
                    >();
                    if (storageInstallationsResponse.ok) {
                        localStorage.setItem(
                            "storageInstallations",
                            JSON.stringify(storageInstallationsResponse.data)
                        );
                        this.storageInstallations = localStorage.getItem(
                            "storageInstallations"
                        );
                        this.habemusData = true;
                        if (
                            this.storageInstallations != "" &&
                            this.storageInstallations != null &&
                            this.storageInstallations != undefined
                        ) {
                            this.storageData = JSON.parse(
                                this.storageInstallations
                            );
                            this.listEmpresa();
                        }
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                    this.setFilterLoading(true);
                    this.fetchCompany();
                    this.setFilterLoading(false);
                }
            } else {
                this.habemusData = true;
                this.storageData = JSON.parse(this.storageInstallations);
                this.listEmpresa();
            }
        } else {
            console.log("Role undefined");
        }
    }

    listEmpresa() {
        if (this.habemusData) {
            try {
                this.companies = [];
                if (this.storageData != null && this.storageData != undefined) {
                    this.storageData.forEach(element => {
                        if (
                            !this.companies.includes(
                                `${element.Nempresa} - ${element.Tempresa}`
                            )
                        ) {
                            this.companies.push(
                                `${element.Nempresa} - ${element.Tempresa}`
                            );
                        }
                    });
                }
                if (this.isFromMap && this.installFromMap) {
                    this.formValues.company = this.installFromMap.empresa;
                } else {
                    this.formValues.company = this.companies[0];
                }
                this.listCliente();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
                this.fetchCompany();
            }
        } else {
            this.fetchCompany();
        }
    }

    listCliente() {
        if (this.habemusData) {
            try {
                this.clients = [];
                this.instantClient = [];
                this.storageData.forEach(element => {
                    if (
                        this.formValues.company.split(" - ")[0] ==
                        element.Nempresa
                    ) {
                        this.instantClient.push(element);
                        if (
                            !this.clients.includes(
                                `${element.Ncliente} - ${element.Tcliente}`
                            )
                        ) {
                            this.clients.push(
                                `${element.Ncliente} - ${element.Tcliente}`
                            );
                        }
                    }
                });
                if (this.isFromMap && this.installFromMap) {
                    this.formValues.client = this.installFromMap.cliente;
                } else {
                    this.formValues.client = this.clients[0];
                }
                this.listInstalacion();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
                this.fetchClient();
            }
        } else {
            this.fetchClient();
        }
    }

    listInstalacion() {
        if (this.habemusData) {
            try {
                this.installations = [];
                this.instantInstall = [];
                this.instantClient.forEach(element => {
                    if (
                        this.formValues.client.split(" - ")[0] ==
                        element.Ncliente
                    ) {
                        this.instantInstall.push(element);
                        if (
                            !this.installations.includes(
                                `${element.Ninstala} - ${element.Tinstala}`
                            )
                        ) {
                            this.installations.push(
                                `${element.Ninstala} - ${element.Tinstala}`
                            );
                        }
                    }
                });
                this.installations = this.sortList(this.installations);
                if (this.isFromMap && this.installFromMap) {
                    this.formValues.installation = this.installFromMap.instalacion;
                } else {
                    this.formValues.installation = this.installations[0];
                }
                this.listElementos();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
                this.fetchInstallation();
            }
        } else {
            this.fetchInstallation();
        }
    }

    installationFromMap() {
        try {
            const install = localStorage.getItem("installationFromMaps");
            if (install != "" && install != null && install != undefined) {
                this.installFromMap = JSON.parse(install);
                this.isFromMap = true;
                this.listEmpresa();
            } else {
                throw Error;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError("Instalación no encontrada");
            }
        }
    }

    listElementos() {
        if (this.habemusData) {
            // ({"Ninstala":client[0],"Tinstala":client[1],"Ncliente":client[2],"Tcliente":client[3],"Nempresa":client[4],"Tempresa":client[5],"Nsocio":client[6],"Tsocio":client[7],
            //           "Nele":client[8],"Tele":client[9],"dir":client[10],"Nfam":client[11],"Tip":client[12],"Tserie":client[13],"Nestanda":client[14],"Tlugar":client[15],"Ntipo":client[16],
            //           "Npadre":client[17],"Nservicio":client[18]})
            try {
                this.listElements = [];
                this.listInversors = [];
                this.listServices = [];
                if (this.path == "/energy") {
                    this.formValues.CantEle = 0;
                }

                this.instantInstall.forEach(element => {
                    if (
                        this.formValues.installation.split(" - ")[0] ==
                        element.Ninstala
                    ) {
                        const inst = `${element.Ninstala} - ${element.Tinstala}`;
                        let name =
                            element.Npadre == 0
                                ? element.Ninstala != 352
                                    ? `${element.Nele} - ${element.Tlugar}/${element.Tele}`
                                    : `${element.Nele} - ${element.Tele}`
                                : `${element.Npadre}/${element.Nele} - ${element.Tele}`;
                        let box = element.Tserie;
                        let analyzer: string | null = null;
                        if (element.Nestanda != 0 && element.Nestanda != 4) {
                            if (
                                element.Tip != "192.168.250.250" &&
                                element.Ninstala != 292 &&
                                element.Ninstala != 304
                            ) {
                                analyzer = element.Tip;
                            }
                        }
                        let Tdir = element.dir;
                        // switch (element.Nfam) {
                        //     case 1:
                        //         if (
                        //             element.Nestanda == 1 ||
                        //             element.Nestanda == 2
                        //         ) {
                        //             Tdir = "P" + (element.dir - 10);
                        //         } else {
                        //             Tdir = "P" + element.dir;
                        //         }
                        //         break;
                        //     case 4:
                        //         Tdir = "E" + element.dir;
                        //         break;
                        //     case 7:
                        //         Tdir =
                        //             element.Nestanda < 4
                        //                 ? "S" + element.dir
                        //                 : "F" + element.dir;
                        //         break;
                        //     case 12:
                        //         Tdir = "P0";
                        //         break;
                        //     case 13:
                        //         Tdir = element.dir;
                        //         break;
                        //     default:
                        //         Tdir = "Unknown";
                        // }
                        let jerar = 0;
                        let nPadre: string | null = null;
                        const NpaCons = element.Npadre;
                        const Ntipo = element.Ntipo;
                        if (this.path != "/energy") {
                            if (element.Npadre == 0) {
                                switch (element.Nservicio) {
                                    case 1:
                                        if (Ntipo != 6) {
                                            this.listElements.push([
                                                inst,
                                                box,
                                                analyzer,
                                                name,
                                                Tdir,
                                                jerar,
                                                NpaCons,
                                                nPadre,
                                                Ntipo
                                            ]);
                                        } else {
                                            this.listElements.push([
                                                inst,
                                                box,
                                                analyzer,
                                                name,
                                                element.Tele,
                                                jerar,
                                                NpaCons,
                                                nPadre,
                                                Ntipo
                                            ]);
                                        }
                                        break;
                                    case 2:
                                        this.listServices.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            "Gas",
                                            NpaCons,
                                            jerar,
                                            nPadre,
                                            element.Nestanda
                                        ]);
                                        break;
                                    case 3:
                                        this.listServices.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            "Agua",
                                            NpaCons,
                                            jerar,
                                            nPadre,
                                            element.Nestanda
                                        ]);
                                        break;
                                    case 4:
                                        if (element.Ninstala == 304) {
                                            name = "549 - CGBT/GenN2";
                                            box = "1907_0449_24";
                                            Tdir = "F7";
                                            analyzer = "3";
                                        } else if (element.Ninstala == 292) {
                                            name = "280 - CGBT/GenN2";
                                            box = "1907_0458_24";
                                            Tdir = "F7";
                                            analyzer = "3";
                                        }
                                        this.listServices.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            "Nitrogeno",
                                            NpaCons,
                                            jerar,
                                            nPadre,
                                            element.Nestanda
                                        ]);
                                        break;
                                    case 5:
                                        this.listServices.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            "Calorifico",
                                            NpaCons,
                                            jerar,
                                            nPadre,
                                            element.Nestanda
                                        ]);
                                        break;
                                    default:
                                        this.listServices.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            "Toneladas de Democracia",
                                            NpaCons,
                                            jerar,
                                            nPadre,
                                            element.Nestanda
                                        ]);
                                }
                            }
                        } else {
                            if (element.Npadre != 0) {
                                [jerar, nPadre] = this.getJerar(element);
                            } else {
                                this.formValues.CantEle =
                                    this.formValues.CantEle !== null &&
                                    this.formValues.CantEle !== undefined &&
                                    element.Nservicio == 1
                                        ? this.formValues.CantEle + 1
                                        : 0;
                            }
                            switch (element.Nservicio) {
                                case 1:
                                    if (element.Nfam == 12) {
                                        this.listInversors.push([
                                            inst,
                                            box,
                                            analyzer,
                                            name,
                                            Tdir,
                                            jerar,
                                            NpaCons,
                                            nPadre,
                                            Ntipo
                                        ]);
                                    } else {
                                        if (Ntipo != 6) {
                                            this.listElements.push([
                                                inst,
                                                box,
                                                analyzer,
                                                name,
                                                Tdir,
                                                jerar,
                                                NpaCons,
                                                nPadre,
                                                Ntipo
                                            ]);
                                        } else {
                                            this.listElements.push([
                                                inst,
                                                box,
                                                analyzer,
                                                name,
                                                element.Tele,
                                                jerar,
                                                NpaCons,
                                                nPadre,
                                                Ntipo
                                            ]);
                                        }
                                    }
                                    break;
                                case 2:
                                    this.listServices.push([
                                        inst,
                                        box,
                                        analyzer,
                                        name,
                                        Tdir,
                                        "Gas",
                                        NpaCons,
                                        jerar,
                                        nPadre,
                                        element.Nestanda
                                    ]);
                                    break;
                                case 3:
                                    this.listServices.push([
                                        inst,
                                        box,
                                        analyzer,
                                        name,
                                        Tdir,
                                        "Agua",
                                        NpaCons,
                                        jerar,
                                        nPadre,
                                        element.Nestanda
                                    ]);
                                    break;
                                case 4:
                                    this.listServices.push([
                                        inst,
                                        box,
                                        analyzer,
                                        name,
                                        Tdir,
                                        "Nitrogeno",
                                        NpaCons,
                                        jerar,
                                        nPadre,
                                        element.Nestanda
                                    ]);
                                    break;
                                case 5:
                                    this.listServices.push([
                                        inst,
                                        box,
                                        analyzer,
                                        name,
                                        Tdir,
                                        "Calorifico",
                                        NpaCons,
                                        jerar,
                                        nPadre,
                                        element.Nestanda
                                    ]);
                                    break;
                                default:
                                    this.listServices.push([
                                        inst,
                                        box,
                                        analyzer,
                                        name,
                                        Tdir,
                                        "Toneladas de Democracia",
                                        NpaCons,
                                        jerar,
                                        nPadre,
                                        element.Nestanda
                                    ]);
                            }
                        }
                    }
                });
                if (this.path == "/energy") {
                    this.formValues.CantInv = this.listInversors.length;
                }
                this.toJSON();
                if (this.listServices.length) {
                    this.toJSONservices();
                }
                if (this.isFromMap && this.installFromMap) {
                    this.isFromMap = false;
                    this.setFilter();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
                this.fetchAll();
            }
        } else {
            this.fetchAll();
        }
    }

    getJerar(element) {
        if (element.Npadre == 0) {
            return [0, null];
        } else {
            for (const ind in this.instantInstall) {
                if (this.instantInstall[ind]["Nele"] == element.Npadre) {
                    let [jerar, name] = this.getJerar(this.instantInstall[ind]);
                    jerar = jerar + 1;
                    name =
                        this.instantInstall[ind]["Npadre"] == 0
                            ? this.instantInstall[ind]["Ninstala"] != 352
                                ? `${this.instantInstall[ind]["Nele"]} - ${this.instantInstall[ind]["Tlugar"]}/${this.instantInstall[ind]["Tele"]}`
                                : `${this.instantInstall[ind]["Nele"]} - ${this.instantInstall[ind]["Tele"]}`
                            : `${this.instantInstall[ind]["Npadre"]}/${this.instantInstall[ind]["Nele"]} - ${this.instantInstall[ind]["Tele"]}`;
                    return [jerar, this.instantInstall[ind]["Tele"]];
                }
            }
        }
    }

    async fetchCompany() {
        this.setFilterLoading(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.formValues.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchClient() {
        this.setFilterLoading(true);
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.formValues.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.formValues.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchInstallation() {
        this.setFilterLoading(true);
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.formValues.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                if (
                    Number(this.formValues.client.split(" - ")[0]) == 293 &&
                    this.path != "/energy"
                ) {
                    this.installations = installResponse.data.filter(
                        x => x.split(" - ")[0] != "509"
                    );
                } else {
                    this.installations = installResponse.data;
                }
                this.formValues.installation = this.installations[0];
                this.fetchAll();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchAll() {
        this.setFilterLoading(true);
        try {
            let allResponse: rdsa = { data: [], ok: false, status: 0 };
            switch (this.path) {
                case "/energy":
                    allResponse = await this.$api.SQLallgen<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        true,
                        true
                    );
                    break;
                case "/resumen":
                    allResponse = await this.$api.SQLallgen<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        false,
                        false
                    );
                    break;
                case "/costes":
                    allResponse = await this.$api.SQLallgen<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        false,
                        false
                    );
                    break;
                default:
                    allResponse = await this.$api.SQLallgen<rdsa>(
                        this.formValues.installation.split(" - ")[0]
                    );
                    break;
            }
            if (allResponse.ok) {
                this.listElements = allResponse.data["element"];
                if (this.path == "/energy") {
                    this.formValues.CantEle = allResponse.data["CantEle"];
                    this.formValues.CantInv = allResponse.data["CantInv"];
                }
                this.listInversors = allResponse.data["inversor"];
                this.toJSON();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        try {
            let serviceResponse: rdsa = { data: [], ok: false, status: 0 };
            switch (this.path) {
                case "/energy":
                    serviceResponse = await this.$api.SQLelementoService<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        true,
                        true
                    );
                    break;
                case "/resumen":
                    serviceResponse = await this.$api.SQLelementoService<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        false,
                        true
                    );
                    break;
                case "/costes":
                    serviceResponse = await this.$api.SQLelementoService<rdsa>(
                        this.formValues.installation.split(" - ")[0],
                        false,
                        true
                    );
                    break;
                default:
                    serviceResponse = await this.$api.SQLelementoService<rdsa>(
                        this.formValues.installation.split(" - ")[0]
                    );
                    break;
            }
            if (serviceResponse.ok) {
                this.listServices = serviceResponse.data;
                this.toJSONservices();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
        // try {
        //     if (
        //         this.path == "/energy" ||
        //         this.path == "/datadis"
        //     ) {
        //         const datadisResponse = await this.$api.SQLelementoDatadis<
        //             rdsa
        //         >(this.formValues.installation.split(" - ")[0]);
        //         if (datadisResponse.ok) {
        //             this.listDatadis = datadisResponse.data;
        //             this.toJSONdatadis();
        //         }
        //     }
        // } catch (error) {
        //     if (error instanceof Error) {
        //         this.showError(error.message);
        //     }
        // }
    }

    toJSON() {
        this.setFilterLoading(true);
        try {
            this.elements = [];
            this.childElements = [];
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) == 0) {
                    this.elements.push(elemento[3]);
                }
                if (this.path == "/energy") {
                    this.paramElments[elemento[3]] = {
                        install: elemento[0],
                        box: elemento[1],
                        analyzer: elemento[2],
                        Tdir: elemento[4],
                        Jerar: elemento[5],
                        NpaCons: elemento[6],
                        nPadre: elemento[7],
                        ntipo: elemento[8]
                    };
                } else if (
                    Number(elemento[8]) != 6 &&
                    Number(elemento[6]) == 0
                ) {
                    this.paramElments[elemento[3]] = {
                        install: elemento[0],
                        box: elemento[1],
                        analyzer: elemento[2],
                        Tdir: elemento[4],
                        Jerar: elemento[5],
                        NpaCons: elemento[6],
                        nPadre: elemento[7]
                    };
                }
            }
            this.selected = this.elements;
            this.inversors = [];
            for (const elemento of this.listInversors) {
                this.inversors.push(elemento[3]);
                this.paramInversors[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4],
                    Jerar: elemento[5],
                    NpaCons: elemento[6],
                    nPadre: elemento[7]
                };
            }
            this.getElementChild();
            this.elements = this.sortList(this.elements);
            this.inversors = this.sortList(this.inversors);
            this.setElements();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    toJSONdatadis() {
        this.setFilterLoading(true);
        try {
            this.datadis = [];
            for (const elemento of this.listDatadis) {
                this.datadis.push(elemento[0]);
                this.paramsDatadis[elemento[0]] = {
                    box: elemento[1],
                    user: elemento[2],
                    pass: elemento[3]
                };
            }
            this.selectedDatadis = this.datadis;
            this.setDatadis();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    toJSONservices() {
        this.setFilterLoading(true);
        try {
            this.services = {};
            const aux: string[] = [];
            this.listServices.forEach(i => {
                if (!aux.includes(i[5])) {
                    aux.push(i[5]);
                    this.services[i[5]] = [];
                }
            });
            for (const elemento of this.listServices) {
                this.services[elemento[5]].push(elemento[3]);
                this.paramsServices[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4],
                    service: elemento[5],
                    NpaCons: elemento[6],
                    Jerar: elemento[7],
                    nPadre: elemento[8],
                    Nestanda: elemento[9]
                };
            }
            for (const serv in this.services) {
                this.services[serv] = this.sortList(this.services[serv]);
            }

            this.selectedServices = JSON.parse(JSON.stringify(this.services));
            this.setServices();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    setElements() {
        this.formValues.elements = [];
        if (this.path != "/energy" && this.path != "/energy") {
            for (const elemento of this.selected) {
                this.formValues.elements.push({
                    install: this.paramElments[elemento]["install"],
                    box: this.paramElments[elemento]["box"],
                    analyzer: this.paramElments[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramElments[elemento]["Tdir"]
                });
            }
        } else {
            for (const elemento of this.selected) {
                this.formValues.elements.push({
                    install: this.paramElments[elemento]["install"],
                    box: this.paramElments[elemento]["box"],
                    analyzer: this.paramElments[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramElments[elemento]["Tdir"],
                    Jerar: this.paramElments[elemento]["Jerar"],
                    NpaCons: this.paramElments[elemento]["NpaCons"],
                    nPadre: this.paramElments[elemento]["nPadre"],
                    ntipo: this.paramElments[elemento]["ntipo"]
                });
                if (!this.formValues.noHijos) {
                    for (const child of this.selectedChild) {
                        if (child.split("/")[0] == elemento.split(" - ")[0]) {
                            this.formValues.elements.push({
                                install: this.paramElments[child]["install"],
                                box: this.paramElments[child]["box"],
                                analyzer: this.paramElments[child]["analyzer"],
                                Telement: child,
                                Tdir: this.paramElments[child]["Tdir"],
                                Jerar: this.paramElments[child]["Jerar"],
                                NpaCons: this.paramElments[child]["NpaCons"],
                                nPadre: this.paramElments[child]["nPadre"],
                                ntipo: this.paramElments[child]["ntipo"]
                            });
                            for (const grandChild of this.selectedChild) {
                                if (
                                    child.split("/")[1].split(" - ")[0] ==
                                    grandChild.split("/")[0]
                                ) {
                                    this.formValues.elements.push({
                                        install: this.paramElments[grandChild][
                                            "install"
                                        ],
                                        box: this.paramElments[grandChild][
                                            "box"
                                        ],
                                        analyzer: this.paramElments[grandChild][
                                            "analyzer"
                                        ],
                                        Telement: grandChild,
                                        Tdir: this.paramElments[grandChild][
                                            "Tdir"
                                        ],
                                        Jerar: this.paramElments[grandChild][
                                            "Jerar"
                                        ],
                                        NpaCons: this.paramElments[grandChild][
                                            "NpaCons"
                                        ],
                                        nPadre: this.paramElments[grandChild][
                                            "nPadre"
                                        ],
                                        ntipo: this.paramElments[grandChild][
                                            "ntipo"
                                        ]
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setFilterLoading(false);
    }

    getElementChild() {
        this.childElements = [];
        this.selectedChild = [];
        for (const elementoPadre of this.selected) {
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) > 0) {
                    if (elemento[6] == elementoPadre.split(" - ")[0]) {
                        this.childElements.push(elemento[3]);
                    }
                }
            }
        }
        this.selectedChild = this.childElements;
        this.getElementGrandchild();
    }

    getElementGrandchild() {
        for (const elementoHijo of this.selectedChild) {
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) > 1) {
                    if (
                        elemento[6] ==
                        elementoHijo.split(" - ")[0].split("/")[1]
                    ) {
                        this.childElements.push(elemento[3]);
                    }
                }
            }
        }
        this.selectedChild = this.childElements;
        this.getInversor();
    }

    getInversor() {
        this.inversors = [];
        for (const elementoPadre of this.selected) {
            for (const elemento in this.paramInversors) {
                if (
                    this.paramInversors[elemento].NpaCons ==
                    elementoPadre.split(" - ")[0]
                ) {
                    this.inversors.push(elemento);
                }
            }
        }
        for (const elementoHijo of this.selectedChild) {
            for (const elemento in this.paramInversors) {
                if (
                    this.paramInversors[elemento].NpaCons ==
                    elementoHijo.split(" - ")[0].split("/")[1]
                ) {
                    this.inversors.push(elemento);
                }
            }
        }
        this.Iselected = this.inversors;
        this.setInversors();
    }

    setInversors() {
        this.setFilterLoading(true);
        this.formValues.inversors = [];
        for (const elemento of this.Iselected) {
            if (this.formValues.inversors) {
                this.formValues.inversors.push({
                    install: this.paramInversors[elemento]["install"],
                    box: this.paramInversors[elemento]["box"],
                    analyzer: this.paramInversors[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramInversors[elemento]["Tdir"],
                    Jerar: this.paramInversors[elemento]["Jerar"],
                    NpaCons: this.paramInversors[elemento]["NpaCons"],
                    nPadre: this.paramInversors[elemento]["nPadre"]
                });
            }
        }
        this.setFilterLoading(false);
    }

    setDatadis() {
        this.setFilterLoading(true);
        this.formValues.datadis = [];
        for (const elemento of this.selectedDatadis) {
            if (this.formValues.datadis) {
                this.formValues.datadis.push({
                    install: elemento,
                    box: this.paramsDatadis[elemento]["box"],
                    user: this.paramsDatadis[elemento]["user"],
                    pass: this.paramsDatadis[elemento]["pass"]
                });
            }
        }
        this.setFilterLoading(false);
    }

    setServices() {
        this.setFilterLoading(true);
        this.formValues.services = [];

        for (const serv in this.selectedServices) {
            this.selectedServices[serv].forEach(elemento => {
                if (this.formValues.services) {
                    this.formValues.services.push({
                        install: this.paramsServices[elemento]["install"],
                        box: this.paramsServices[elemento]["box"],
                        analyzer: this.paramsServices[elemento]["analyzer"],
                        Telement: elemento,
                        Tdir: this.paramsServices[elemento]["Tdir"],
                        service: this.paramsServices[elemento]["service"],
                        Jerar: this.paramsServices[elemento]["Jerar"],
                        NpaCons: this.paramsServices[elemento]["NpaCons"],
                        nPadre: this.paramsServices[elemento]["nPadre"],
                        Nestanda: this.paramsServices[elemento]["Nestanda"]
                    });
                }
            });
        }
        this.setFilterLoading(false);
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    windowUpdate() {
        if (this.formValues.comparative) {
            const c = Number(this.formValues.days);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1dia";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "1hora";
            }
        } else if (
            this.formValues.dates.length > 1 &&
            this.path != "/resumen"
        ) {
            const a = new Date(this.formValues.dates[0]);
            const b = new Date(this.formValues.dates[1]);
            const c = Math.abs(Number(b) - Number(a)) / (3600000 * 24);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1dia";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1hora", "1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1hora", "1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "1hora";
            }
        } else if (!this.formValues.comparative && this.path == "/resumen") {
            const c = Number(this.formValues.days);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1dia";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "1hora";
            }
            this.formValues.dates[1] = new Date().toISOString().split("T")[0];
            this.formValues.dates[0] = new Date(
                new Date(this.formValues.dates[1]).setDate(
                    new Date(this.formValues.dates[1]).getDate() -
                        Number(this.formValues.days) +
                        1
                )
            )
                .toISOString()
                .split("T")[0];
        } else {
            this.periodType = ["15minutos", "1hora"];
            this.formValues.window = "1hora";
        }
    }

    sortList(iterable) {
        const aux: string[] = [];
        const sortedlist: string[] = [];
        iterable.forEach(x => {
            aux.push(
                x
                    .split(" - ")
                    .slice(1)
                    .join(" - ")
            );
        });
        aux.sort();
        aux.forEach(x => {
            const a = iterable.filter(
                y =>
                    y
                        .split(" - ")
                        .slice(1)
                        .join(" - ") == x
            );
            if (a.length) {
                sortedlist.push(a[0]);
            }
        });
        return sortedlist;
    }

    deselectAll() {
        this.selected = [];
        this.selectedChild = [];
        this.selectedDatadis = [];
        this.selectedServices = [];
        this.Iselected = [];
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.formValues.services = [];
    }

    resetForm0() {
        this.isFromMap = false;
        this.formValues.client = "";
        this.formValues.installation = "";
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.formValues.services = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.datadis = [];
        this.selectedDatadis = [];
        this.services = {};
        this.selectedServices = {};
        this.listServices = [];
        this.$emit("no-csv");
    }

    resetForm1() {
        this.isFromMap = false;
        this.formValues.installation = "";
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.formValues.services = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.datadis = [];
        this.selectedDatadis = [];
        this.services = {};
        this.selectedServices = {};
        this.listServices = [];
        this.$emit("no-csv");
    }

    resetForm2() {
        this.isFromMap = false;
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.formValues.services = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.datadis = [];
        this.selectedDatadis = [];
        this.services = {};
        this.selectedServices = {};
        this.listServices = [];
        this.$emit("no-csv");
    }

    resetForm3() {
        this.isFromMap = false;
        this.$emit("no-csv");
    }
}
